body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7266ba;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5b509a;
}

::-webkit-scrollbar-corner {}


.active {
  background-color: #4aa916;
  color: white;
}

.table-bill-detail thead th {
  position: sticky;
  top: 0px;
  background-color: #7266ba;
}

.table-border {
  border: solid 1px rgba(0, 0, 0, 0.175);;
}

.row-bill-detail {
  border: solid 0px rgba(0, 0, 0, 0.175);
  padding: 5px;
  border-radius: 0.375rem;
  margin: 0
}

.col-bill-detail {
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: bold;
}